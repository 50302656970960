const tariff = [
  {
    id: 1,
    name: "ניתוח תיק מלא",
    info: `
    הוצאת נתונים מהר הביטוח, מהמסלקה וניתוח עומק של המידע , בדיקת עלויות, דמי ניהול, מסלולי השקעה, אלטרנטיבות לניהול כסף פנוי, צוואות וירושות,נדל"ן, הגדרת מטרות פיננסיות ובניית תקציב לניהול סיכונים.
    `,
    price: "₪5,000",
  },
  {
    id: 2,
    name: "ניתוח תיק סיכונים ובריאות",
    info: `
   בדיקת עלויות מול אלטרנטיבה, בדיקת כפילויות והצגה ללקוח הוצאת נתונים מהר הביטוח,שליחת יפוי כח לחברות, התאמה לחיובים בבנק.
    `,
    price: "₪3,000",
  },
  {
    id: 3,
    name: "תכנון פרישה",
    info: `
    בחינת חלופות בעת יציאה לפרישה תוך מזעור חבות המס, הפרדת גמל ופיצויים לפי ייעוד להון וקצבה, בדיקת טפסי 161 . ליווי לקוח מול רשויות המס בעת הצורך.
    `,
    price: "₪4,500 - ₪10,000",
  },
  {
    id: 4,
    name: "ליווי מתמשך בעלות קבועה",
    info: `
זמינות וטיפול אישי, ביצוע כל הפעולות ללא עלות נוספת פגישת שירות שנתית,עדכון והתאמה של התיק הביטוחי/פנסיוני.
    `,
    price: " ₪160 לת.ז לחודש ",
  },
  {
    id: 5,
    name: "פגישת שרות חד פעמית / תקופתית  למי שאינו לקוח הסוכנות",
    info: `
    פגישה אישית לבקשת הלקוח שאינו לקוח הסוכנות.
    `,
    price: "₪380 לשעה",
  },
  {
    id: 6,
    name: "שעת פגישה ראשונה בבית הלקוח",
    info: `
    הכרות , תיאום ציפיות וקבלת החלטה על אסטרטגיית ליווי גדרה -חדרה (מעבר לגבולות).
    `,
    price: "₪750- ₪350",
  },
  {
    id: 7,
    name: "שעת פגישה ראשונה במשרד",
    info: `
הכרות, תיאום ציפיות וקבלת החלטה על אסטרטגיית ליווי.
    `,
    price: "ללא עלות",
  },
  {
    id: 8,
    name: "פגישת שירות שנתית",
    info: `הכנת דוח כיסויים מרוכז על תיק הלקוח בסוכנות.`,
    price: "₪350",
  },
  {
    id: 9,
    name: "מינוי סוכן על פוליסות קיימות ללקוח חדש",
    info: `
    קבלת פוליסה קיימת לטיפול שוטף.
    `,
    price: "₪350 - ₪550",
  },
  {
    id: 10,
    name: "טיפול בתביעה בפוליסה חיצונית",
    info: `
    טיפול, הכוונה וסיוע בהגשת תביעה למיצוי זכויות פנסיה, נכות, שאירים, סיעוד, בריאות, אכע, תאונות אישיות ועוד (בתשלום לשיעורין עד 36 תשלומים)
    `,
    price: "20% על פי הצלחה",
  },
]

export default tariff
