import React from "react"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import Head from "../components/Head"
import styled from "styled-components"
import tariff from "../../data/tariff"
import breakpoints from "../components/GlobalStyle/globals/breakpoints"

const E = {
  Content: styled.div`
    overflow: hidden;
    z-index: 2;
    background: #fff;
    padding: 30px;
  `,

  ServiceBox: styled.div`
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    h2 {
      text-align: start;
      font-weight: bold;
      flex: 0 0 80%;
      padding: 10px 0;
             @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
  text-align: right;
}
    
      @media (max-width: ${breakpoints.large}px) {
        flex: 0 0 100%;
        text-align: center;
      }
      @media (max-width: ${breakpoints.medium}px) {
        font-size: 1.3rem;
      }
      @media (max-width: ${breakpoints.small}px) {
        text-align: center;
        font-size: 1.2rem;
      }
    }

    p {
      flex: 0 0 60%;

      @media (max-width: ${breakpoints.large}px) {
        flex: 0 0 100%;
      }
    }

    span {
      margin-right: auto;
      padding: 10px;
      flex: 0 0 20%;
      background: rgba(241, 241, 241, 0.7);
      border-radius: 6px;
      display: felx;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      text-align: center;

      &:before {
        content: "מחיר:";
        width: 100%;
        display: block;
      }

      @media (max-width: ${breakpoints.large}px) {
        flex: 0 0 30%;
        margin: 20px auto;
      }
      @media (max-width: ${breakpoints.medium}px) {
        flex: 0 0 40%;
      }
      @media (max-width: ${breakpoints.small}px) {
        flex: 0 0 60%;
      }
    }
  `,
}

const TariffPage = () => {
  return (
    <Layout>
      <Seo title="תעריפון" />
      <Head title="תעריפון" />
      <section>
        <div className="container">
          <E.Content>
            {tariff.map(item => (
              <E.ServiceBox key={item.id}>
                <h2>{item.name}</h2>
                <p>{item.info}</p>
                <span>{item.price}</span>
              </E.ServiceBox>
            ))}
          </E.Content>
        </div>
      </section>
    </Layout>
  )
}

export default TariffPage
